// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blink-cursor {
    caret-color: transparent;
    animation: blink-caret 1s step-end infinite;
  }
  
  @keyframes blink-caret {
    50% {
        caret-color: black;
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,2CAA2C;EAC7C;;EAEA;IACE;QACI,kBAAkB;IACtB;EACF","sourcesContent":[".blink-cursor {\n    caret-color: transparent;\n    animation: blink-caret 1s step-end infinite;\n  }\n  \n  @keyframes blink-caret {\n    50% {\n        caret-color: black;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
