// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducerLogin from './Login';
import userReducerReport from './ReportContents';
import userChatMessages from './ChatMessages';
import userSelectedPathologies from './SelectedPathologies';
import userSelectedModStudy from './SelectedModStudy';
import pathologyFormData from './PathologyFormData';
import Reportbuilder from './ReportBuilder';
import augnito from './Augnito';
import { createSlice } from '@reduxjs/toolkit';

// Create a slice for session storage handling if needed
const sessionStorageSlice = createSlice({
  name: 'sessionStorage',
  initialState: {
    formData: JSON.parse(sessionStorage.getItem('formData')) || {}, // Load from session storage
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
      sessionStorage.setItem('formData', JSON.stringify(state.formData)); // Save to session storage
    },
    clearFormData: (state) => {
      state.formData = {};
      sessionStorage.removeItem('formData'); // Clear from session storage
    },
  },
});

export const { setFormData, clearFormData } = sessionStorageSlice.actions;

// Configure the store
export const store = configureStore({
  reducer: {
    user: userReducerLogin,
    reportContents: userReducerReport,
    chatMessages: userChatMessages,
    selectedPathologies: userSelectedPathologies,
    selectedModStudy: userSelectedModStudy,
    pathologyFormData: pathologyFormData,
    augnito: augnito,
    reportBuilder: Reportbuilder,
    sessionStorage: sessionStorageSlice.reducer, // Add the session storage slice
  },
});

// Subscribe to store changes to store formData in session storage
store.subscribe(() => {
  const { formData } = store.getState().reportBuilder;
  sessionStorage.setItem('formData', JSON.stringify(formData)); // Store in session storage
});
