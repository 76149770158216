import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoCloseCircleOutline } from "react-icons/io5";
import Navbar from '../utils/Navbar';
import { useNavigate } from 'react-router-dom';
import Dicom from '../utils/Dicom';
import { updateReportContents, updateReportGeneralInfo, resetFormData, updateCurrentStatus, addSelectedPathology } from '../redux/ReportBuilder';
import { resetMessage } from '../redux/ChatMessages';
import { resetPathologyFormData } from '../redux/PathologyFormData';
import { resetSelectedPathology } from '../redux/SelectedPathologies';
import { resetContent } from '../redux/ReportContents';
import { colors } from '../constant';
import Loader from '../components/Loader';

const colorOptions = [
    { color: "green", indicator: "low" },
    { color: "yellow", indicator: "medium" },
    { color: "red", indicator: "high" }
];

function Impression() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formData = useSelector(state => state.reportBuilder.formData);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [hoveredIndicator, setHoveredIndicator] = useState(null);
    const [findingColors, setFindingColors] = useState([]);
    const [adviceTexts, setAdviceTexts] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const initializedRef = useRef(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!initializedRef.current) {
            const selectedPathologies = JSON.parse(sessionStorage.getItem('selectedPathologies')) || [];
            const savedFindingColors = JSON.parse(localStorage.getItem('findingColors')) || [];

            if (selectedPathologies.length > 0 && formData.report_by_pathology.report_contents) {
                const updatedReportContents = formData.report_by_pathology.report_contents.map(content => {
                    const pathology = content.pathology;
                    const savedFinding = savedFindingColors.find(f => f.name.toLowerCase() === pathology.toLowerCase());

                    if (savedFinding) {
                        return { ...content, color: savedFinding.color, indicator: savedFinding.indicator };
                    }

                    const matchingColor = colors.find(colorData =>
                        colorData.pathology.toLowerCase() === pathology.toLowerCase()
                    );

                    return {
                        ...content,
                        color: matchingColor ? matchingColor.details.color : 'gray',
                        indicator: matchingColor ? matchingColor.details.indicator : 'unknown'
                    };
                });

                // Update Redux store with all updated report contents at once
                dispatch(updateReportContents(updatedReportContents));

                // Update local state
                const filteredFindings = updatedReportContents.map(content => ({
                    name: content.pathology,
                    color: content.color,
                    indicator: content.indicator,
                    impression: content.impression,
                    advice: content.advice || ''
                }));

                setFindingColors(filteredFindings);
                setAdviceTexts(filteredFindings.map(f => f.advice || ''));

                initializedRef.current = true;
            }
        }
    }, [dispatch, formData.report_by_pathology.report_contents]);



    const handleIndicatorClick = (index) => {
        const currentColor = findingColors[index].color;
        const currentIndex = colorOptions.findIndex(option => option.color === currentColor);
        const nextIndex = (currentIndex + 1) % colorOptions.length;
        const { color: nextColor, indicator: nextIndicator } = colorOptions[nextIndex];

        const updatedFindingColors = findingColors.map((c, i) =>
            i === index ? { ...c, color: nextColor, indicator: nextIndicator } : c
        );
        setFindingColors(updatedFindingColors);

        updateReduxState(updatedFindingColors[index].name, { color: nextColor, indicator: nextIndicator });
    };

    const handleAdviceChange = (event, index) => {
        const updatedAdviceTexts = adviceTexts.map((advice, i) => i === index ? event.target.value : advice);
        setAdviceTexts(updatedAdviceTexts);

        const updatedFindingColors = findingColors.map((finding, i) =>
            i === index ? { ...finding, advice: event.target.value } : finding
        );
        setFindingColors(updatedFindingColors);

        updateReduxState(findingColors[index].name, { advice: event.target.value });
    };

    const handleAdviceKeyDown = (event, index) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setEditingRow(null);
        }
    };



    const updateReduxState = (pathologyName, updates) => {
        const updatedReportContents = formData.report_by_pathology.report_contents.map(content => {
            if (content.pathology.toLowerCase() === pathologyName.toLowerCase()) {
                return { ...content, ...updates };
            }
            return content;
        });

        dispatch(updateReportContents(updatedReportContents));

        const updatedFormData = {
            ...formData,
            report_by_pathology: {
                ...formData.report_by_pathology,
                report_contents: updatedReportContents
            }
        };
        sessionStorage.setItem('formData', JSON.stringify(updatedFormData));
    };

    const handleSubmit = () => {
    setIsLoading(true);

    dispatch(updateReportContents(formData.report_by_pathology.report_contents));
    console.log("Submitting updated report contents:", formData.report_by_pathology.report_contents);
    dispatch(updateCurrentStatus("Completed"));

    const selectedPathologies = JSON.parse(sessionStorage.getItem('selectedPathologies'));
    if (selectedPathologies) {
        dispatch(addSelectedPathology(selectedPathologies));
    }

    // dispatch(resetMessage());
    // dispatch(resetContent([]));
    // dispatch(resetSelectedPathology([]));
    // dispatch(resetPathologyFormData({}));
    // dispatch(resetFormData());

    const insertValue = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/insert-value`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify([formData]),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Value insertion response:", data);
            return response.status;
        } catch (error) {
            console.error("Failed to insert value:", error);
            throw error;
        }
    };

    const updateStatus = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BIONIC_BACKEND_API + '/update-status', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    patient_id: formData.patient_id,
                    new_status: "Completed"
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Status update response:", data);
            return response.status;
        } catch (error) {
            console.error("Failed to update status:", error);
            throw error;
        }
    };

    Promise.all([insertValue(), updateStatus()])
        .then(([insertStatus, updateStatus]) => {
            if (insertStatus === 200 && updateStatus === 200) {
                navigate("/report");
            } else {
                console.error("One or both API calls did not return a 200 status");
            }
        })
        .catch(error => {
            console.error("An error occurred during the submission process:", error);
        })
        .finally(() => {
            setIsLoading(false);
        });
};


    console.log("formdatavaluefromimpression", formData)

    return (
        <>
        {isLoading ? <Loader /> : (
            <div>
            <Navbar user_name={formData?.patient_name || 'Guest'} mod_study={formData?.mod_study} patient_age={formData?.patient_age} patient_sex={formData.patient_sex} />
            <div className="flex pt-8">
                <div className="w-1/2 p-4 pb-0">
                    <Dicom />
                </div>

                <div className="w-1/2 p-8 pb-0 pl-2">
                    <div className="overflow-x-auto mb-8 shadow-sm">
                        <table className="w-full border-collapse text-center">
                            <thead>
                                <tr className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white text-center">
                                    <th className="py-3 px-4 text-left rounded-tl-lg">Findings</th>
                                    <th className="py-3 px-4">Indicator</th>
                                    <th className="py-3 px-4 rounded-tr-lg">Advice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {findingColors.map((finding, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b border-gray-200 transition-all duration-300 ease-in-out ${hoveredRow === index ? 'bg-blue-50' : 'bg-white'}`}
                                        onMouseEnter={() => setHoveredRow(index)}
                                        onMouseLeave={() => setHoveredRow(null)}
                                    >
                                        <td className="py-3 px-4 text-left">{finding.name}</td>
                                        <td className="py-3 px-4 cursor-pointer">
                                            <div className="relative inline-block">
                                                <span
                                                    className={`inline-flex items-center justify-center w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${hoveredRow === index ? 'scale-125' : 'scale-100'}`}
                                                    style={{ backgroundColor: finding.color }}
                                                    onClick={() => handleIndicatorClick(index)}
                                                    onMouseEnter={() => setHoveredIndicator(index)}
                                                    onMouseLeave={() => setHoveredIndicator(null)}
                                                >
                                                    {/* <span className="text-white text-xs font-bold">
                                                    {getIndicatorDisplay(finding.indicator)}
                                                    </span> */}
                                                </span>
                                                {hoveredIndicator === index && (
                                                    <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 bg-gray-800 text-white text-xs rounded-lg opacity-100 transition-opacity duration-300 w-32">
                                                        <div className="px-2 py-2 text-center">
                                                            Color: {finding.color}<br />
                                                            Criticality: {finding.indicator}
                                                        </div>
                                                        <div className="absolute left-1/2 transform -translate-x-1/2 top-full">
                                                            <div className="border-solid border-t-gray-800 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className="py-3 px-4">
                                            {editingRow === index ? (
                                                <input
                                                    type="text"
                                                    value={adviceTexts[index]}
                                                    onChange={(event) => handleAdviceChange(event, index)}
                                                    onBlur={() => setEditingRow(null)}
                                                    onKeyDown={(event) => handleAdviceKeyDown(event, index)}
                                                    className="border rounded px-2 py-1 w-full"
                                                    autoFocus
                                                />
                                            ) : (
                                                <span onClick={() => setEditingRow(index)}>
                                                    {adviceTexts[index] || 'Click to add advice'}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-right">
                        <button
                            className="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-2 px-6 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
                            onClick={handleSubmit}
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
            </div>
            </div>
        )}
        </>
    );
}

export default Impression;