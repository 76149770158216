import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../utils/Navbar';
import { Download, House, FileText } from 'lucide-react';
import { Document, Page, pdfjs } from 'react-pdf';
import Loader from '../components/Loader';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const Report = () => {
  const formData = useSelector(state => state.reportBuilder.formData);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const navigate = useNavigate();

  const generatePdf = async () => {
    setError(null);
    try {
      const patientId=formData.patient_id
      const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/get-pdf-buffer?patient_id=${patientId}`);
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Report not found. Retrying...');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const pdfBuffer = await response.arrayBuffer();
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
      setPdfBlob(blob);
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setIsLoading(false);
    } catch (error) {
      console.error("Error generating the PDF:", error);
      if (retryCount < 3 && error.message.includes('Report not found')) {
        setRetryCount(prevCount => prevCount + 1);
        setTimeout(generatePdf, 2000); // Retry after 2 seconds
      } else {
        setError(error.message || "Failed to generate the PDF. Please try again.");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      generatePdf();
    }, 2000); // Initial 2-second delay

    return () => {
      clearTimeout(timer);
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []);

  const handleDownload = () => {
    if (pdfBlob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.setAttribute('download', 'report.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClick = () => {
    sessionStorage.removeItem('formData');
    sessionStorage.removeItem('editorContent');
    sessionStorage.removeItem('selectedPathologies');
    navigate("/list");
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar user_name={formData?.patient_name || 'Guest'} mod_study={formData?.mod_study} patient_age={formData?.patient_age} patient_sex={formData.patient_sex} />

      <div className='container mx-auto px-4 py-8'>
        <div className='flex justify-between mb-6'>
          <button
            className="flex items-center justify-center gap-2 text-lg font-semibold py-2 px-4 bg-white text-teal-700 hover:bg-teal-50 hover:text-teal-800 transition-all duration-300 rounded-lg shadow-md"
            onClick={handleClick}
          >
            <House className="w-6 h-6" />
            Return to Homepage
          </button>
          <div className='flex gap-4'>
            <button
              onClick={handleDownload}
              disabled={!pdfBlob || isLoading}
              className="flex items-center justify-center gap-2 text-lg font-semibold py-2 px-4 bg-white text-amber-600 hover:bg-amber-50 hover:text-amber-700 transition-all duration-300 rounded-lg shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Download className="w-6 h-6" />
              Download
            </button>
          </div>
        </div>
        <div className='bg-white p-6 rounded-lg shadow-lg'>
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-10">
             <Loader/>
              <p className="mt-4 text-lg text-gray-600">Generating report, please wait...</p>
            </div>
          ) : error ? (
            <div className="text-center text-red-500 mb-4">
              {error}
            </div>
          ) : pdfUrl ? (
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => {
                console.error("Error loading PDF:", error);
                setError("Failed to load the PDF. Please try again.");
              }}
              className="flex flex-col items-center"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  className="mb-5 shadow-md"
                  scale={1.5}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  error={(error) => (
                    <div className="text-center text-red-500">
                      Error rendering page {index + 1}: {error.message}
                    </div>
                  )}
                />
              ))}
            </Document>
          ) : (
            <div className="text-center text-gray-500 py-10">
              No report generated yet. Please wait or try refreshing the page.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;