import logo from '../assets/logo.png';
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from '../redux/Login';
import { flipChatBot } from '../redux/ReportContents';
import { logoutUser } from '../redux/Login';
import userlogo from '../assets/kalyan.jpeg';
import { IoArrowBackCircleOutline } from "react-icons/io5";

function Navbar({ user_name,mod_study,patient_age,patient_sex }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const formattedSex = patient_sex.toUpperCase().charAt(0);
  


  
  

  const handleProfile = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleOutsideClick = (event) => {
    if (isMenuVisible && !event.target.closest('.flex-none')) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isMenuVisible]);


  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/auth');
  };

  const handleBackButton = () => {
    switch (location.pathname) {
      case '/impression':
        navigate('/clinical-image');
        break;
      case '/clinical-image':
        navigate('/report-builder');
        break;
      case '/report-builder':
        navigate('/list');
        break;
      default:
        navigate('/list');
    }
  };



  return (

    <div class="navbar bg-[#edf4ff] pl-6">
      <div class="flex-1">
        {location.pathname === '/list' && (
          <img
            src={logo}
            alt=""
            className="h-10 mr-6 cursor-pointer"
            onClick={() => navigate('/')}
          />
        )}        
        {location.pathname !== '/list' && (
        <div className="inline-flex items-center">
          <IoArrowBackCircleOutline
            className="text-2xl mr-2 cursor-pointer text-[#3d3d3d]"
            size={30}
            onClick={handleBackButton}
          />
          <div>
        <h1 className="text-xl font-bold text-[#274a57]">{mod_study}</h1>
        <p className="text-sm text-gray-600 tracking-[0.3px]">
          {user_name} ({formattedSex} / {patient_age})
        </p>
      </div>
        </div>
      )}
        {/* <p class="text-2xl font-bold">Bionic Report</p> */}
      </div>

      <div class="flex-none gap-2 relative">

        <img
          style={{ borderRadius: "50%" }}
          className="w-12 h-12 cursor-pointer"
          alt=""
          src={userlogo}
          onClick={handleProfile}
        />
        {isMenuVisible && (
          <div
          className="absolute right-0 top-full mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50 cursor-pointer" 
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
            <div className="py-1" role="none">
              {/* <a
                className="text-gray-700 block px-4 py-2 border-b text-sm"
                role="menuitem"
                id="menu-item-0"
              >
                Profile
              </a> */}
              <button
                type="button"
                className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
                role="menuitem"
                id="menu-item-3"
                onClick={handleLogout}
              >
                Log Out
              </button>
            </div>
          </div>
        )}
      </div>
    </div>

  )
};

export default Navbar;