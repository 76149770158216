import React, { useEffect, useRef, useState } from 'react';
import "quill-mention";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { pathology_options, toolbarOptions, placeholder } from '../constant';
import { useSelector, useDispatch } from 'react-redux';
import { removeReport, resetContent, flipChatQuestion, replaceAdditionalImpression, replaceAddtionalObservation } from '../redux/ReportContents';
import { resetMessage, addMessage } from '../redux/ChatMessages';
import { addPathology, removePathology, resetSelectedPathology, flipIsReportable, flipIsGenerating } from '../redux/SelectedPathologies';
import { setCursorPosition, setSizeInput, setTextArea, setDataFromAugnito, augnitoStartListening, augnitoStopListening, whisperStopListening, whisperStartListening } from '../redux/Augnito';
import {
  streamFullReportContent, streamPathologyContent, copyHTMLToClipboard, updateObservationVariables,
  getOptionFromReportContents, findVariableProperty, checkForEligibleAdditionalQuestion
} from './Helper'
import { updatePathologyFormData, resetPathologyFormData } from '../redux/PathologyFormData';
import { _getProperty } from 'gsap/gsap-core';
import { addReportContent,updateHtmlContent } from '../redux/ReportBuilder';


function Editor({ onSendMessageStream }) {
  const augnitoData = useSelector((state) => state.augnito.augnitoData);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.user);
  const { reportContents,
    selectedModStudyValue,
    selectedPathologies,
    isReportable,
    isGenerating,
    pathologyFormData,
    quillDictate,
    sizeInputDictate, //For size textbox value
    isChatBotActive,
  } = useSelector(state => ({
    reportContents: state.reportContents.reportContents,
    selectedModStudyValue: state.selectedModStudy.selectedModStudyValue,
    selectedPathologies: state.selectedPathologies.selectedPathologies,
    isReportable: state.selectedPathologies.isReportable,
    isGenerating: state.selectedPathologies.isGenerating,
    pathologyFormData: state.pathologyFormData.pathologyFormData,
    quillDictate: state.augnito.quillDictate,
    sizeInputDictate: state.augnito.sizeInputDictate,
    isChatBotActive: state.reportContents.isChatBotActive,
  }));

  const dispatch = useDispatch();
  const isOptionClickedRef = useRef(false);
  const cursorRef = useRef(null);
  let pathologyForSearchRef = useRef({});


  const [isAIReportable, setIsAIReportable] = useState(false);
  const [isMentionActive, setIsMentionActive] = useState(false);
  const [selectedLocalPathologies, setSelectedLocalPathologies] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [isFullReportDisabled, setIsFullReportDisabled] = useState(false);

  const quillRef = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [formPosition, setFormPosition] = useState({ left: 0, top: 0 });
  const [currentLabel, setCurrentLabel] = useState('');
  const [selectedPathologyOnClick, setSelectedPathologyOnClick] = useState('');
  const [startwriting, setstartwriting] = useState(false)
  const [selectedModStudyValues, setSelectedModStudyValue] = useState('');

  const formData = useSelector(state => state.reportBuilder.formData);




  useEffect(() => {
    if (formData.patient_sex) {
      const modStudyValue = formData.patient_sex.toLowerCase() === 'm' ? 'spine_male' : 'spine_female';
      setSelectedModStudyValue(modStudyValue);
    }
  }, [formData.patient_sex]);

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    quill.on('selection-change', handleSelectionChange);
    quill.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        const regex = /@(\w+)/g;
        const match = regex.exec(quill.getText());

        if (match) {
          let pathologyForSearch = [];
          if (selectedModStudyValues && pathology_options[selectedModStudyValues]) {
            pathology_options[selectedModStudyValues].forEach(element => {
              if (element.toLowerCase().includes(match[1].toLowerCase())) {
                pathologyForSearch.push(element);
              }
            });
          }
          pathologyForSearchRef.current.pathologyForSearch = pathologyForSearch;
          pathologyForSearchRef.current.index = match.index;
          pathologyForSearchRef.current.value = match[1];

          if (!pathologyForSearchRef.current.pathologyForSearch.length) {
            setIsMentionActive(false);
            return;
          }

          const bounds = quill.getBounds(match.index);
          const editorContainer = quill.container.getBoundingClientRect();
          const formLeft = bounds.left + editorContainer.left;
          const formTop = bounds.bottom + editorContainer.top;
          setFormPosition({ left: formLeft, top: formTop });
          setIsMentionActive(true);
          return;
        }
      }
    });

  }, [selectedModStudyValues]);



  const setIsOptionClicked = (value) => {
    isOptionClickedRef.current = value;
    handleSelectionChange(null)
  }

  //it gives the pathology name using @
  const hamdleSelectFromMention = (value) => {
    if (pathologyForSearchRef.current?.pathologyForSearch.length) {
      quillRef.current.getEditor().deleteText(pathologyForSearchRef.current.index, pathologyForSearchRef.current.value.length);
      handleOnPathologySelect(value);
    }
    setIsMentionActive(false);
  }

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    if (quillDictate.length) {

      quill.insertText(cursorRef.current, quillDictate);
      cursorRef.current += quillDictate.length

    }

  }, [quillDictate]);

  // it tracks the cursor where it's present in the editor currently.
  const handleSelectionChange = (range) => {
    setIsMentionActive(false);
    if (range) {
      const quill = quillRef.current.getEditor();
      const clickedIndex = range.index;
      const text = quill.getText();
      const labelRegex = /_\w+/g;
      let match;
      while ((match = labelRegex.exec(text)) !== null) {
        const labelStart = match.index;
        const labelEnd = match.index + match[0].length;
        if (clickedIndex >= labelStart && clickedIndex <= labelEnd) {

          const currentLabel = text.slice(labelStart, labelEnd);
          setCurrentLabel(currentLabel);
          const bounds = quill.getBounds(range.index);
          const editorContainer = quill.container.getBoundingClientRect();

          const formLeft = bounds.left + editorContainer.left * 1.05;
          const formTop = bounds.bottom + editorContainer.top * 1.01;
          setFormPosition({ left: formLeft, top: formTop });
          setShowForm(true);
          cursorRef.current = null

          dispatch(setCursorPosition('sizeInput'))
          setIsOptionClicked(true);

          const blot = quill.getLeaf(range.index)[0];
          const paragraphBlot = blot.parent;
          const paragraphElement = paragraphBlot.domNode;
          const paragraphId = paragraphElement.id;
          setSelectedPathologyOnClick(paragraphId.replace('obs-', ''))
          return;
        }
        else {
          setIsOptionClicked(false)
        }
      }
      cursorRef.current = range.index
      dispatch(setCursorPosition('quill'))

    }

    if (isOptionClickedRef.current) return;
    setShowForm(false);
  };

  // it gives the report content after selecting the pathology in html
  useEffect(() => {
    if (!isChatBotActive) {
      const responseHtml = streamPathologyContent(reportContents, null);
      quillRef.current.getEditor().root.innerHTML = responseHtml;
    }
  }, [reportContents]);




  //it executes when the button is clicked within the label
  const handleLabelSelect = (label) => {
    const quill = quillRef.current.getEditor();
    const labelStartIndex = quill.getText().indexOf(currentLabel);
    const labelEndIndex = labelStartIndex + currentLabel.length;
    const content = reportContents.find((c) => c.pathology === selectedPathologyOnClick);

    if (content.observation_init.includes(currentLabel)) {
      let impression_init_copy = content.impression_init
      let observation_init_copy = content.observation_init

      if (Object.keys(pathologyFormData).length) {
        if (pathologyFormData[selectedPathologyOnClick]) {
          for (const [key, value] of Object.entries(pathologyFormData[selectedPathologyOnClick])) {
            if (key !== currentLabel.replace('_', '')) {
              impression_init_copy = impression_init_copy.replace(new RegExp(`\\b_${key}\\b`, 'g'), value);
              observation_init_copy = observation_init_copy.replace(new RegExp(`\\b_${key}\\b`, 'g'), value);
            }
          }
        }
      }

      impression_init_copy = impression_init_copy.replace(new RegExp(`\\b${currentLabel}\\b`, 'g'), label);
      observation_init_copy = observation_init_copy.replace(new RegExp(`\\b${currentLabel}\\b`, 'g'), label);

      dispatch(replaceAdditionalImpression({
        'pathology': selectedPathologyOnClick,
        'impression': impression_init_copy
      }))
      dispatch(replaceAddtionalObservation({
        'pathology': selectedPathologyOnClick,
        'observation': observation_init_copy
      }))

    }

    dispatch(updatePathologyFormData({ key: currentLabel.replace('_', ''), value: label, pathology: selectedPathologyOnClick }));

    const updatedPathologyFormData = {
      ...pathologyFormData,
      [selectedPathologyOnClick]: {
        ...pathologyFormData[selectedPathologyOnClick],
        [currentLabel.replace('_', '')]: label,
      },
    }

    const additionalQuestionsMessage = checkForEligibleAdditionalQuestion(reportContents, updatedPathologyFormData)

    if (additionalQuestionsMessage) {

      setTimeout(() => {
        dispatch(flipChatQuestion(true));
        dispatch(addMessage({ 'sender': 'Bot', 'text': additionalQuestionsMessage, 'question': true }));
      }, 500);

    }

    quill.deleteText(labelStartIndex, labelEndIndex - labelStartIndex);
    quill.insertText(labelStartIndex, label);

    setShowForm(false);
  };

  const handleOnClearClick = () => {
    dispatch(resetMessage());
    dispatch(resetContent([]));
    dispatch(resetSelectedPathology([]));
    dispatch(resetPathologyFormData({}));
    setSelectedLocalPathologies([]);
    const responseHtml = streamPathologyContent([], null);
    quillRef.current.getEditor().setContents(responseHtml);
    setIsFullReportDisabled(false);
  };


  const handleMultipleSelect = (e) => {
    e.preventDefault();
    const selectedLabels = Array.from(e.target.elements).filter((element) => element.type === 'checkbox' && element.checked).map((element) => element.value).join(', ');
    if (selectedLabels.length) {
      handleLabelSelect(selectedLabels);
    }
  }


  useEffect(() => {

    let lastElement;
    const restOfReportContents = [];

    reportContents.forEach(item => {
      item.isLast ? lastElement = item : restOfReportContents.push(item);
    });
    if (!isAIReportable) {
      const responseHtml = streamPathologyContent(restOfReportContents, lastElement);
      quillRef.current.getEditor().root.innerHTML = responseHtml;

      if (!isReportable) return;

      if (lastElement && document.getElementById(`obs-${lastElement['pathology']}`)) {
        let index = 0;

        const lastElement_observation = document.getElementById(`obs-${lastElement['pathology']}`).innerHTML

        const intervalId = setInterval(() => {
          if (index < lastElement_observation + 3) {
            document.getElementById(`obs-${lastElement['pathology']}`).innerHTML = lastElement_observation.slice(0, index);
          } else {
            clearInterval(intervalId);
            dispatch(flipIsGenerating(false));

            if (lastElement && document.getElementById(`imp-${lastElement['pathology']}`)) {
              let index_x = 0;
              const intervalId_x = setInterval(() => {
                if (index_x < lastElement['impression'].length + 3) {
                  document.getElementById(`imp-${lastElement['pathology']}`).innerHTML = lastElement['impression'].slice(0, index_x);
                } else {
                  clearInterval(intervalId_x);
                }
                index_x += 3;
              }, process.env.REACT_APP_STREAMING_OBS);
            }
          }
          index += 3;
        }, process.env.REACT_APP_STREAMING_OBS);

      }

    } else {
      const responseHtml = streamFullReportContent(reportContents, selectedModStudyValues);

      let index = 0;
      const intervalId = setInterval(() => {
        if (index < responseHtml.length + 5) {
          quillRef.current.getEditor().root.innerHTML = responseHtml.slice(0, index);
        } else {
          clearInterval(intervalId);
          dispatch(flipIsGenerating(false));
          setIsAIReportable(false);
        }
        index += 5;
      }, process.env.REACT_APP_STREAMING_WHOLE);
    }

    dispatch(flipIsReportable(false));
  }, [isReportable]);

  //it returns the full report content from the backend
  useEffect(() => {
    const newReportContents = [...reportContents];

    for (const [index, pathology] of selectedLocalPathologies.entries()) {
      if (newReportContents.some(item => item.pathology === pathology)) {
        continue;
      }

      fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/api/get-observation?pathology=${pathology}&mod_study=${selectedModStudyValues}`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_BIONIC_BACKEND_API_TOKEN}`
        }
      }).then(async (response) => {
        const data = await response.json();
        data.forEach(iter => {

          const impression = iter['impression'] === '-' ? '' : iter['impression'];
          const isLast = index === selectedLocalPathologies?.length - 1;

          newReportContents.push({
            pathology,
            observation_init: iter['observation'],
            observation: iter['observation'],
            variable: iter['variable'],
            question: iter['question'],
            impression_init: impression,
            impression,
            isLast,
            isToBeQuestioned: iter['variable'] && Object.keys(iter['variable']).length > 0
          });
        });

        if (newReportContents.length > 1) {
          let updatedReportContent = { ...newReportContents[newReportContents.length - 2], isLast: false };
          newReportContents.splice(newReportContents.length - 2, 1, updatedReportContent);
        }
        dispatch(resetContent(newReportContents));
        dispatch(addPathology(pathology));
      })
      // .catch((e) => {
      //   throw new Error(`HTTP error! Status: ${e}`);
      // });
    }
  }, [selectedLocalPathologies]);

  //it extracts placeholder value as list of dict (type,key,value). it executes after the full report is clicked
  const extractPlaceholder = (data) => {
    for (const key in data) {
      if (data[key].place_holder === 'true') {
        return data[key].key;
      }
    }
  }

  //full report functionality
  const handleOnFullReportClick = (isNormalReport) => {
    let tempReportContents = isNormalReport ? [] : [...reportContents];

    console.log("tempreportcontents", tempReportContents)

    const textBlocks = quillRef.current.getEditor().root.querySelectorAll('p');
    tempReportContents = tempReportContents.map(item => {
      const elements = Array.from(textBlocks).filter(block => block.getAttribute('id') === 'obs-' + item.pathology);

      const addtionalPlaceholder = pathologyFormData[item.pathology]?.[extractPlaceholder(item?.question)]
      if (addtionalPlaceholder) {
        return elements.length > 0 ? {
          ...item,
          observation: elements.map(element => element.innerHTML).join('\n'),
          pathology: item.pathology,
          organ: placeholder?.[selectedModStudyValues]?.[extractPlaceholder(item?.question)][addtionalPlaceholder],
          placeholder: item.pathology + ' - ' + addtionalPlaceholder,
        } : {
          ...item,
          pathology: item.pathology,
          organ: placeholder?.[selectedModStudyValues]?.[extractPlaceholder(item?.question)][addtionalPlaceholder],
          placeholder: item.pathology + ' - ' + addtionalPlaceholder,
        };

      }
      return elements.length > 0 ? { ...item, observation: elements.map(element => element.innerHTML).join('\n') } : item;
    });
    tempReportContents = tempReportContents.map(item => {
      const elements = Array.from(textBlocks).filter(block => block.getAttribute('id') === 'imp-' + item.pathology);
      return elements.length > 0 ? { ...item, impression: elements.map(element => element.innerHTML).join('\n') } : item;
    });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_BIONIC_BACKEND_API_TOKEN}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ reportContents: tempReportContents, mod_study: selectedModStudyValues }),
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/api/refine-report`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        if (response.status === 200) {
          setIsFullReportDisabled(true); // Disable the button on successful response
        }
        return response.json();
      })
      .then(result => {
        if (!Array.isArray(result)) {
          throw new Error("[Slate] initialValue is invalid! Expected a list of elements but got: undefined");
        }
        result.forEach((tempContent) => {
          const resultContent = result.find(content => content.pathology === tempContent.pathology);
          if (resultContent) {
            // Check if placeholder exists and update the content
            if (tempContent?.placeholder) {
              const updatedContent = {
                ...tempContent,
                organ: 'ab ' + tempContent.organ,
                advice: '', // Initialize additional fields
                color: '',
                image_url: []
              };
              Object.assign(resultContent, updatedContent);
            } else {
              const initialContent = {
                ...tempContent,
                advice: '', // Initialize additional fields
                color: '',
                image_url: []
              };
              Object.assign(resultContent, initialContent);
            }

            // Dispatch addReportContent action to add to Redux state
            // dispatch(addReportContent({
            //   pathology: resultContent.pathology,
            //   observation: resultContent.observation,
            //   impression: resultContent.impression,
            //   organ: resultContent.organ,
            //   isLast: resultContent.isLast,
            //   advice: resultContent.advice,
            //   color: resultContent.color,
            //   image_url: resultContent.image_url
            // }));
          }
        });
        dispatch(resetContent(result));
        console.log("reportcontentsdata", { result })
        const observationData = result.map(item => item.observation);
        const impressionData = result.map(item => item.impression);
        const pathologyValues = result.filter(item => item.pathology !== 'normal').map(item => item.pathology);
        const pathologyValuescondition = pathologyValues.map(value => {
          if (augnitoData.includes(value)) {
            return value + "(augnito)";
          } else {
            return value + "(dropdown)";
          }
        });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "report": {
            "observation": observationData.join(', '),
            "impression": impressionData.join(', '),
            "user_name": userData.email
          },
          "types": pathologyValues.map(value => ({
            "pathology_name": value,
            "selection_type": augnitoData.includes(value) ? "augnito" : "dropdown"
          }))
        });


        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}insert_report_content`, requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.error(error));

        dispatch(flipIsReportable(true));

      })
      .catch(error=>{
        console.log('error', error)
        setIsFullReportDisabled(false);
      });

    dispatch(flipIsGenerating(true));
    setIsAIReportable(true);
  };



  //pathology selection using the textbox
  const handleOnPathologySelect = (selectedPathology) => {
    if (!selectedPathologies || !selectedPathologies.includes(selectedPathology)) {
      dispatch(flipIsGenerating(true));
      dispatch(addMessage({ 'text': selectedPathology, 'sender': 'You' }));

      let reportContentUpdated = reportContents.map(content => {
        if (content.pathology !== 'normal') {
          return content;
        }
      });
      reportContentUpdated = reportContentUpdated.filter(content => content !== undefined);
      dispatch(resetContent(updateObservationVariables(reportContentUpdated, pathologyFormData)));
      setSelectedLocalPathologies([...selectedLocalPathologies, selectedPathology]);
    }
    setInputValue('');
  };
  // delete pathology functionality
  const handleOnDeletePathology = (pathologyToSelect) => {
    dispatch(removePathology(pathologyToSelect));
    dispatch(removeReport(pathologyToSelect));
  };

  const variableType = findVariableProperty(reportContents, selectedPathologyOnClick, currentLabel, 'type');



  const findColorRange = (quill, color) => {
    let ranges = [];
    if (quill) {
      quill.scroll.descendants(function (blot) {
        if (blot.statics.blotName === 'inline' && blot.formats().background === color) {
          let index = blot.offset(quill.scroll);
          ranges.push({ index: index, length: blot.length() });
        }
      });
    }
    return ranges;
  };

  const logColorRanges = () => {
    const quill = quillRef.current?.getEditor();
    const searchColor = '#f5f7a6';
    const colorRanges = findColorRange(quill, searchColor);
    let currentIndex = 0;

    const handleNextRange = () => {
      if (currentIndex < colorRanges.length) {
        handleSelectionChange(colorRanges[currentIndex]);
        currentIndex++;
      }
    };

    handleNextRange();
  };


  const handleEditorFocus = () => {
    setstartwriting(true)
  };

  const handleClick = () => {
    const editorContent = sessionStorage.getItem('editorContent');
    dispatch(updateHtmlContent(editorContent));
    navigate('/clinical-image')
  };

//store the editor data in session
  useEffect(() => {
    const savedContent = sessionStorage.getItem('editorContent');
    if (savedContent) {
      setEditorContent(savedContent);
    }
  }, []);

  //show the editor content from session
  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      if (editorContent) {
        quill.root.innerHTML = editorContent;
      }

      const handleTextChange = () => {
        const content = quill.root.innerHTML;
        setEditorContent(content);
        sessionStorage.setItem('editorContent', content);
      };

      quill.on('text-change', handleTextChange);

      return () => {
        quill.off('text-change', handleTextChange);
      };
    }
  }, [quillRef.current]);






  return (
    <div className=' mr-3'>
      <div className='h-[6vh] pt-3 overflow-x-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch ' >
        <div className="flex flex-nowrap gap-3  whitespace-nowrap">
          {selectedPathologies?.length > 0 ? selectedPathologies.map(label => (
            <span key={label} className={`flex items-center text-black text-l font-semibold px-2.5 py-1.5 rounded bg-blue-50`} >
              {label.length > 17 ? label.substring(0, 17) + ".." : label}
              <button onClick={() => handleOnDeletePathology(label)} className="ml-3 text-black-500">
                &times;
              </button>
            </span>
          )) : <div className=""></div>}
        </div>
      </div>
      <div className="border-2 rounded-2xl " style={{ boxShadow: '0px 2px 2px 0px #d3d3d3' }}>
        <div className="flex flex-grow py-3 px-3 rounded-t-2xl bg-blue-50 justify-between items-center">
          {/* Left Section (Text Input) */}
          <div className="w-[25vh]">
            <input
              type="text"
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              disabled={isGenerating}
              style={isGenerating ? { filter: "blur(1px)" } : {}}
              placeholder="Add pathology"
              className="w-full px-3 py-2 rounded-2xl shadow-sm focus:outline-none"
            />
            {inputValue && pathology_options[selectedModStudyValues] && (
  <ul className="absolute z-10 w-[40vh] bg-white border border-gray-300 mt-1 rounded shadow-lg max-h-60 overflow-auto">
    {pathology_options[selectedModStudyValues]
      .filter((option) =>
        option.toLowerCase().includes(inputValue.toLowerCase())
      )
      .slice(0, 10) // Show the first 10 values
      .map((option) => (
        <li
          key={option}
          onClick={() => handleOnPathologySelect(option)}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
        >
          {option}
        </li>
      ))}
  </ul>
)}


          </div>

          {/* Right Section (Buttons) */}
          <div className="flex items-center space-x-3">
            <button
              id="stream-button"
              className="bg-white border border-blue-700 font-semibold py-1 px-3 rounded inline-flex items-center"
              onClick={() => handleOnFullReportClick(false)}
              disabled={isFullReportDisabled || isGenerating}
              style={isGenerating ? { filter: "blur(1px)" } : {}}
            >
              <svg width="28" height="28" viewBox="0 0 11 11" fill="black" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.86748 4.80655V3.97992H8.04084V3.15328C8.04019 2.93424 7.95289 2.72436 7.798 2.56948C7.64312 2.41459 7.43324 2.32729 7.2142 2.32664H6.38757V1.5H5.56093V2.32664H4.73429V1.5H3.90765V2.32664H3.08101C2.86197 2.32729 2.6521 2.41459 2.49721 2.56948C2.34233 2.72436 2.25503 2.93424 2.25437 3.15328V3.97992H1.42773V4.80655H2.25437V5.63319H1.42773V6.45983H2.25437V7.28647C2.25503 7.50551 2.34233 7.71539 2.49721 7.87027C2.6521 8.02515 2.86197 8.11245 3.08101 8.11311H3.90765V8.93975H4.73429V8.11311H5.56093V8.93975H6.38757V8.11311H7.2142C7.43324 8.11245 7.64312 8.02515 7.798 7.87027C7.95289 7.71539 8.04019 7.50551 8.04084 7.28647V6.45983H8.86748V5.63319H8.04084V4.80655H8.86748ZM7.2142 7.28647H3.08101V3.15328H7.2142V7.28647Z" fill="black" />
                <path d="M4.88473 3.56677H4.32882L3.49805 6.87333H3.92253L4.11431 6.09835H5.07155L5.25837 6.87333H5.69732L4.88473 3.56677ZM4.1701 5.76728L4.59086 3.95943H4.60988L5.01617 5.76728H4.1701ZM6.07633 3.56677H6.48965V6.87333H6.07633V3.56677Z" fill="black" />
              </svg>
              <span className="text-sm text-black">Full&nbsp;Report</span>
            </button>

            <button
              id="stream-button"
              className="bg-white border border-blue-700 font-semibold py-1 px-3 rounded inline-flex items-center"
              onClick={() => handleOnFullReportClick(true)}
              disabled={isGenerating}
              style={isGenerating ? { filter: "blur(1px)" } : {}}
            >
              <svg width="28" height="28" viewBox="0 0 11 11" fill="black" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.86748 4.80655V3.97992H8.04084V3.15328C8.04019 2.93424 7.95289 2.72436 7.798 2.56948C7.64312 2.41459 7.43324 2.32729 7.2142 2.32664H6.38757V1.5H5.56093V2.32664H4.73429V1.5H3.90765V2.32664H3.08101C2.86197 2.32729 2.6521 2.41459 2.49721 2.56948C2.34233 2.72436 2.25503 2.93424 2.25437 3.15328V3.97992H1.42773V4.80655H2.25437V5.63319H1.42773V6.45983H2.25437V7.28647C2.25503 7.50551 2.34233 7.71539 2.49721 7.87027C2.6521 8.02515 2.86197 8.11245 3.08101 8.11311H3.90765V8.93975H4.73429V8.11311H5.56093V8.93975H6.38757V8.11311H7.2142C7.43324 8.11245 7.64312 8.02515 7.798 7.87027C7.95289 7.71539 8.04019 7.50551 8.04084 7.28647V6.45983H8.86748V5.63319H8.04084V4.80655H8.86748ZM7.2142 7.28647H3.08101V3.15328H7.2142V7.28647Z" fill="black" />
                <path d="M4.88473 3.56677H4.32882L3.49805 6.87333H3.92253L4.11431 6.09835H5.07155L5.25837 6.87333H5.69732L4.88473 3.56677ZM4.1701 5.76728L4.59086 3.95943H4.60988L5.01617 5.76728H4.1701ZM6.07633 3.56677H6.48965V6.87333H6.07633V3.56677Z" fill="black" />
              </svg>
              <span className="text-sm text-black">Normal&nbsp;Report</span>
            </button>

            <button class="bg-white border border-red-500 text-black font-semibold py-1 px-3 rounded inline-flex items-center" onClick={handleOnClearClick}>
              <svg width="24" height="24" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg"  >
                <circle cx="11.9" cy="10.7092" r="10.0408" stroke="black" stroke-width="1.2551" />
                <path d="M16.8673 7.20116L15.9824 6.31631L12.4744 9.82433L8.96639 6.31631L8.08154 7.20116L11.5896 10.7092L8.08154 14.2172L8.96639 15.102L12.4744 11.594L15.9824 15.102L16.8673 14.2172L13.3592 10.7092L16.8673 7.20116Z" fill="black" />
              </svg>
              <span className="text-sm">Clear</span>
            </button>

            {/* Next Button */}
            <button
              onClick={handleClick}
              className="bg-[#1c3363] rounded-full hover:bg-[#162a52] transition-colors"
            >
              <RiArrowRightSLine color="white" size={30} />
            </button>
          </div>
        </div>


        <div id="editor">
          <ReactQuill
            ref={quillRef}
            modules={{
              toolbar: toolbarOptions,
            }}
            onFocus={handleEditorFocus}
          />
          {isMentionActive && (
            <div
              style={{
                position: 'absolute',
                left: formPosition.left,
                top: formPosition.top,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                padding: '8px',
              }}
            >
              {pathologyForSearchRef.current.pathologyForSearch.slice(0, 5).map((label, index) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <button htmlFor={label} style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={(e) => { hamdleSelectFromMention(e.target.innerText) }}>
                    {label}
                  </button>
                </div>
              ))}
            </div>
          )}
          {showForm && (variableType === 'multiple choice' ? (
            <form
              style={{
                position: 'absolute',
                left: formPosition.left,
                top: formPosition.top,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                padding: '8px',
              }}
              onSubmit={(e) => { handleMultipleSelect(e); logColorRanges(); }}
            >
              {getOptionFromReportContents(reportContents, selectedPathologyOnClick, currentLabel)?.map((label, index) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <button type="button" onClick={() => { setShowForm(true); }}>
                    <label htmlFor={label} className='pr-4'>
                      <input type="checkbox" className='mr-2' id={label} name={label} value={label} />
                      {label}
                    </label>
                  </button>
                </div>
              ))}
              <button style={{ border: 'none', float: 'right', backgroundColor: 'white', cursor: 'pointer' }} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 20 20" fill="currentColor" className="h-5 w-5 rotate-90 transform">
                  <path fill="black" d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              </button>
            </form>)
            : variableType === 'single choice' ? (<div
              style={{
                position: 'absolute',
                left: formPosition.left,
                top: formPosition.top,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                padding: '8px',
              }}
            >
              {getOptionFromReportContents(reportContents, selectedPathologyOnClick, currentLabel)?.map((label, index) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <button htmlFor={label} style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={(e) => { handleLabelSelect(e.target.innerText); logColorRanges(); }}>
                    {label}
                  </button>
                </div>
              ))}
            </div>
            ) : variableType === 'input box' && (
              <div
                style={{
                  position: 'absolute',
                  left: formPosition.left,
                  top: formPosition.top,
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  padding: '4px',
                }}
              >
                <input
                  type="text"
                  placeholder="Enter size"
                  value={sizeInputDictate}
                  style={{
                    width: '100px',
                    outline: 'none',
                    border: 'none',
                    '&:focus': {
                      border: '1px solid #ccc',
                    },
                  }}
                  autoFocus
                  onChange={(e) => dispatch(setSizeInput({ 'action': 'reset', 'text': e.target.value }))}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setIsOptionClicked(false)
                      handleLabelSelect(e.target.value)
                      logColorRanges();
                      dispatch(setSizeInput({ 'action': 'reset', 'text': '' }))
                    }
                  }}
                />
              </div>
            )
          )}
        </div>
      </div>

    </div>
  );
}

export default Editor;