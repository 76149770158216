import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './Login';
import Reportbuilder from '../pages/Reportbuilder';
import Clinicalimage from '../pages/Clinicalimage';
import Impression from '../pages/Impression';
import Studieslist from '../pages/Studieslist';
import Report from '../pages/Report';

const AuthWrapper = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const location = useLocation();

  useEffect(() => {
    if (user && location.pathname !== '/auth') {
      sessionStorage.setItem('lastPath', location.pathname); // Change to sessionStorage
    }
  }, [user, location]);

  if (!user) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return children;
};

const App = () => {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call or a check for user authentication
    const checkUser = () => {
      // Simulate a delay to fetch user state
      setTimeout(() => {
        setLoading(false);
      }, 100); // Adjust the delay as needed
    };
    checkUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while checking user state
  }

  return (
    <Router>
      <Routes>
        {/* Redirect to the last path or /list based on authentication */}
        <Route path="/" element={<Navigate to={user ? (sessionStorage.getItem('lastPath') || "/list") : "/auth"} />} />
        <Route 
          path="/auth" 
          element={
            !user ? (
              <Login />
            ) : (
              <Navigate to={sessionStorage.getItem('lastPath') || "/list"} />
            )
          } 
        />
        <Route path="/list" element={<AuthWrapper><Studieslist /></AuthWrapper>} />
        <Route path="/report-builder" element={<AuthWrapper><Reportbuilder /></AuthWrapper>} />
        <Route path="/clinical-image" element={<AuthWrapper><Clinicalimage /></AuthWrapper>} />
        <Route path="/impression" element={<AuthWrapper><Impression /></AuthWrapper>} />
        <Route path="/report" element={<AuthWrapper><Report /></AuthWrapper>} />
      </Routes>
    </Router>
  );
};

export default App;
