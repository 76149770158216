import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Dicom from '../utils/Dicom';
import Navbar from '../utils/Navbar';
import { MdKeyboardCommandKey, MdNavigateNext, MdNavigateBefore, MdZoomOutMap } from "react-icons/md";
import { updateReportContents,addSelectedPathology } from '../redux/ReportBuilder';

function Clinicalimage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formData = useSelector(state => state.reportBuilder.formData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState('');
  const [editorTitles, setEditorTitles] = useState([]);
  const [currentEditorIndex, setCurrentEditorIndex] = useState(0);
  const imageContainerRef = useRef(null);

  useEffect(() => {
    const selectedPathologies = JSON.parse(sessionStorage.getItem('selectedPathologies') || '[]');
    setEditorTitles(selectedPathologies);

    if (!formData.report_by_pathology.report_contents.length) {
      const initialReportContents = selectedPathologies.map(pathology => ({
        pathology,
        observation:'',
        impression:'',
        organ:'',
        advice: '',
        image_url: [],
        color: ''
      }));
      dispatch(updateReportContents(initialReportContents));
    }
  }, []);

  const handlePaste = async (clipboardData) => {
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        await processImage(blob);
        break;
      }
    }
  };

  const openModal = (imageSrc) => {
    setCurrentImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const processImage = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);
        const byteCode = canvas.toDataURL('image/png').split(',')[1];

        const updatedReportContents = formData.report_by_pathology.report_contents.map(content => {
          if (content.pathology === editorTitles[currentEditorIndex]) {
            return {
              ...content,
              image_url: [...content.image_url, byteCode]
            };
          }
          return content;
        });

        dispatch(updateReportContents(updatedReportContents));
      };
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const pasteHandler = (event) => handlePaste(event.clipboardData);
    document.addEventListener('paste', pasteHandler);
    return () => document.removeEventListener('paste', pasteHandler);
  }, [currentEditorIndex]);

  const deleteImage = (indexToDelete) => {
    const updatedReportContents = formData.report_by_pathology.report_contents.map(content => {
      if (content.pathology === editorTitles[currentEditorIndex]) {
        return {
          ...content,
          image_url: content.image_url.filter((_, index) => index !== indexToDelete)
        };
      }
      return content;
    });

    dispatch(updateReportContents(updatedReportContents));
  };

  const handleNext = () => {
    if (currentEditorIndex < editorTitles.length - 1) {
      setCurrentEditorIndex(prevIndex => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentEditorIndex > 0) {
      setCurrentEditorIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleInsert = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          if (type.startsWith('image/')) {
            const blob = await clipboardItem.getType(type);
            await processImage(blob);
            return;
          }
        }
      }
      alert('No image found in clipboard');
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
      alert('Failed to access clipboard. Please ensure you have granted clipboard permissions.');
    }
  };

  const handleImpressionClick = () => {
    const selectedPathologies = JSON.parse(sessionStorage.getItem('selectedPathologies'));
    console.log("selectedpatologies",selectedPathologies)
        if (selectedPathologies) {
            dispatch(addSelectedPathology(selectedPathologies));
        }
    navigate('/impression');
  };

  const getCurrentPathologyImages = () => {
    const currentPathology = editorTitles[currentEditorIndex];
    const currentPathologyData = formData.report_by_pathology.report_contents.find(item => item.pathology === currentPathology);
    
    return currentPathologyData ? currentPathologyData.image_url : [];
  };
  
  const currentImages = getCurrentPathologyImages();

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar user_name={formData?.patient_name || 'Guest'} mod_study={formData?.mod_study} patient_age={formData?.patient_age} patient_sex={formData.patient_sex}  />
      <div className="flex-grow max-w-full p-4">
        <div className="flex gap-4">
          <div className="w-1/2">
            <Dicom />
          </div>
          <div className="w-1/2">
            <h2 className="text-xl font-bold mb-4">Clinical Images</h2>
            <div className="relative flex items-center mb-2">
              <button 
                className="absolute left-0 rounded-full p-2 bg-[#000d26] focus:outline-none"
                onClick={handlePrevious}
                disabled={currentEditorIndex === 0}
              >
                <MdNavigateBefore className='text-white'/>
              </button>

              <div className="flex-1 text-center flex items-center justify-center">
                <h3 className='text-base font-semibold mr-2'>{editorTitles[currentEditorIndex]}</h3>
                <button
                  onClick={handleInsert}
                  className="bg-[#000d26] text-white rounded-full p-2 focus:outline-none"
                  title="Insert image from clipboard"
                >
                  <FiLogIn size={20} />
                </button>
              </div>

              <button 
                className="absolute right-0 rounded-full bg-[#000d26] p-2 focus:outline-none"
                onClick={handleNext}
                disabled={currentEditorIndex === editorTitles.length - 1}
              >
                <MdNavigateNext className='text-white'/>
              </button>
            </div>
            <div
              className="relative h-[35rem] bg-slate-200 p-4 shadow-md rounded-lg overflow-hidden cursor-pointer"
              ref={imageContainerRef}
              onClick={() => imageContainerRef.current.focus()}
              tabIndex="0"
            >
              <div className="h-full overflow-y-auto pr-2">
                <div className="grid grid-cols-2 gap-4 rounded-lg">
                  {currentImages && currentImages.length > 0 ? (
                    currentImages.map((imageData, index) => (
                      <div key={index} className="relative w-[300px] h-[300px]">
                        <img
                          src={`data:image/png;base64,${imageData}`} 
                          alt={`Image ${index}`}
                          className="w-full h-full object-cover"
                        />
                        <button
                          className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs"
                          onClick={() => deleteImage(index)}
                        >
                          &times;
                        </button>
                        <button
                          className="absolute top-1 left-1 bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs"
                          onClick={() => openModal(`data:image/png;base64,${imageData}`)}
                        >
                          <MdZoomOutMap size={16} />
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <p className="text-gray-500 flex items-center whitespace-nowrap">
                        Paste an image from clipboard Ctrl+V
                        <span className="ml-2">or Cmd <MdKeyboardCommandKey className="inline" size={18} /> + V</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-4 flex justify-between">
        <button
          className="bg-[#71768b] text-white font-bold py-2 px-4 rounded-lg flex items-center"
          onClick={() => navigate('/')}
        >
          <ChevronLeft className="mr-2" size={20} />
          Report Section
        </button>
        <button
          className="bg-[#1b3363] text-white font-bold py-2 px-4 rounded-lg flex items-center"
          onClick={handleImpressionClick}
        >
          Impression
          <ChevronRight className="ml-2" size={20} />
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white p-2 max-w-3xl max-h-full overflow-auto">
            <img src={currentImageSrc} alt="Enlarged" className="w-full h-auto" />
            <button
              className="absolute top-0 right-0 m-4 text-white rounded-full p-2 text-4xl"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Clinicalimage;