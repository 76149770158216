import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './utils/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './redux/Store';
import LogRocket from 'logrocket';



LogRocket.init('ridfzo/bionic');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  {/* <React.StrictMode> */}
    <App />
  {/* </React.StrictMode> */}
  </Provider>
);

reportWebVitals();
