import React, { useState, useEffect, useRef } from 'react';
// import Voice from './Voice';
// import Augnito from './Augnito';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../redux/ChatMessages'
import { resetSelectedPathology } from '../redux/SelectedPathologies';
import { resetContent } from '../redux/ReportContents';


function MessageInput() {
    const [inputText, setInputText] = useState('');
    const dispatch = useDispatch();
    const textareaRef = useRef(null);

    const reportContents = useSelector(state => state.reportContents.reportContents);
    const selectedPathologies = useSelector(state => state.selectedPathologies.selectedPathologies);
    const selectedModStudyValue = useSelector(state => state.selectedModStudy.selectedModStudyValue);



    const handleSend = () => {
        
        const fetchReportContent = async (inputText) => {
            const newReportContents = [...reportContents];
            const newSelectedPathologies = [...selectedPathologies];
    
            const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/api/chat-input?chat=${inputText.trim()}&mod_study=${selectedModStudyValue}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_BIONIC_BACKEND_API_TOKEN}`
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            data.forEach(iter => {
                if (!newSelectedPathologies.includes(iter['finding'])) {
                    const impression = iter['impression'] === '-' ? '' : iter['impression'];
                    const isLast = true;
    
                    newReportContents.push({
                        pathology: iter['finding'],
                        observation_init: iter['observation'],
                        observation: iter['observation'],
                        variable: iter['variable'],
                        question: iter['question'],
                        impression_init: impression,
                        impression,
                        isLast,
                        isToBeQuestioned: iter['variable'] && Object.keys(iter['variable']).length > 0
                    });
    
                    newSelectedPathologies.push(iter['finding'])
                }
            });
    
            if (newReportContents.length > 1) {
                let updatedReportContent = { ...newReportContents[newReportContents.length - 2], isLast: false };
                newReportContents.splice(newReportContents.length - 2, 1, updatedReportContent);
            }
            dispatch(resetContent(newReportContents));
            dispatch(resetSelectedPathology(newSelectedPathologies));
        }
        dispatch(addMessage({'text': inputText.trim(), 'sender': 'You'}));
        setInputText('');
        fetchReportContent(inputText)
    };


    const onSendMessageStream = (streamText) => {
        setInputText(prevInputText => prevInputText + ' ' + streamText);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    };

    const autoResizeTextarea = () => {
        if (textareaRef.current) {
            const maxHeight = 100;
            textareaRef.current.style.height = 'auto'; 
            const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
            textareaRef.current.style.height = `${newHeight}px`; 
        }
    };
    

    useEffect(() => {
        autoResizeTextarea();
    }, [inputText]);


    return (
        <div class="mb-2 border-t-2 border-gray-200 px-4 pt-4 sm:mb-0">
            <div class="relative flex">
                 <span class="absolute top-2 flex px-2 ">
                    {/* <Augnito sendMessageStream={onSendMessageStream} ></Augnito> */}
                    {/* <Voice sendMessageStream={onSendMessageStream} /> */}
                </span> 
                <textarea 
                    ref={textareaRef}
                    placeholder="Write your message!" 
                    value={inputText} 
                    onChange={(e) => setInputText(e.target.value)} 
                    onKeyPress={handleKeyPress}
                    onInput={autoResizeTextarea}
                    rows="1"
                    className="w-full resize-none rounded-md bg-gray-200 py-3 pl-12 pr-6 text-gray-600 placeholder-gray-600 focus:placeholder-gray-400 
                    scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-grey-lighter scrollbar-w-2 scrolling-touch"
                    style={{ maxHeight: '100px', overflowY: 'auto' }}
                ></textarea>
                <div class="absolute bottom-1 right-0 sm:flex">
                    <button type="button" onClick={handleSend} class="inline-flex items-center justify-center rounded-lg px-4 py-2 text-white focus:outline-none order-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="ml-2 h-6 w-6 rotate-90 transform">
                            <path fill="black" d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MessageInput;
