import { createSlice } from '@reduxjs/toolkit';
import firebase from '../utils/Firebase';

const loginSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loading: true,
    error: null,
    isEnabled: null
  },
  reducers: {
    loginStart: state => {
      console.log('Login started');
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      console.log('Login successful:', action.payload);
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    loginFailure: (state, action) => {
      console.log('Login failed:', action.payload);
      state.loading = false;
      state.error = action.payload;
    },
    logoutSuccess: state => {
      console.log('Logout successful');
      state.user = null;
      state.loading = false;
    },
    setEnabled: (state, action) => {
      state.isEnabled = action.payload;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logoutSuccess } = loginSlice.actions;

export const loginUser = (email, password) => async dispatch => {
  console.log('Attempting to log in user:', email);
  dispatch(loginStart());
  try {
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
    console.log('Firebase login successful:', userCredential.user);
    dispatch(loginSuccess(userCredential.user));
  } catch (error) {
    console.error('Firebase login error:', error);
    dispatch(loginFailure(error.message));
  }
};

export const logoutUser = () => async dispatch => {
  try {
    await firebase.auth().signOut();
    // const databases = await window.indexedDB.databases();
    // databases.forEach(db => {
    //   window.indexedDB.deleteDatabase(db.name);
    // });
    // localStorage.clear();
    dispatch(logoutSuccess());
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

export default loginSlice.reducer;