import React, { useEffect, useState } from 'react';
import Dictate from '../utils/Dictate';
import Questions from '../utils/Question';
import Navbar from '../utils/Navbar';
import Editor from '../utils/Editor';
import { loginSuccess } from '../redux/Login';
import Login from '../utils/Login';
import CustomDropdown from '../utils/OptionDropDown';
import MessageInput from '../utils/MessageInput';
import { useSelector, useDispatch } from 'react-redux';
import Dicom from '../utils/Dicom'
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ctabdomen from '../assets/ct_abdomen.png';
import Template from '../utils/Template';

function getQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const accessToken = searchParams.get('accessToken');
  const idToken = searchParams.get('idToken');


  return { accessToken, idToken };
}

function Reportbuilder() {
  const { accessToken, idToken } = getQueryParams();
  const user = useSelector((state) => state.user);
  const isChatQuestionActive = useSelector((state) => state.reportContents.isChatQuestionActive);
  const messages = useSelector((state) => state.chatMessages.messages);
  const isChatBotActive = useSelector((state) => state.reportContents.isChatBotActive);
  const augnitoValue = useSelector((state) => state.augnito.valueFromAugntio);
  const [link, setlink] = useState('');
  const handle = useFullScreenHandle();
  const formData = useSelector(state => state.reportBuilder.formData);

  const dispatch = useDispatch();

  useEffect(() => {
    const inputScroll = document.getElementById('inputScroll');
    if (inputScroll) {
      inputScroll.scrollTop = inputScroll.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const validateUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_USER_IDENTITY_API}`, {
          method: 'GET',
          headers: {
            idToken: idToken,
            accessToken: accessToken,
          },
        });
        if (!response.ok) {
          console.error('User validation failed:', response.status);
          return;
        }
        const userData = await response.json();
        dispatch(loginSuccess(userData.user));
      } catch (error) {
        console.error('Error validating user:', error);
      }
    };

    if (accessToken && idToken) {
      validateUser();
    }
  }, [accessToken, idToken]);

  if (!isChatBotActive) {
    const modal = document.getElementById('my_modal_3');
    if (modal) {
      modal.showModal();
    }
  }


  console.log("formadatafromreportbuilder",formData)



  return (
    <>

    <div className="App h-screen flex flex-col">
      <div className="h-[8vh]">
      <Navbar user_name={formData?.patient_name || 'Guest'} mod_study={formData?.mod_study} patient_age={formData?.patient_age} patient_sex={formData.patient_sex}  />
      </div>
      <div className="flex-grow flex overflow-hidden">
        <Questions />
        <div className="flex-grow flex gap-[1em] overflow-hidden">
          <div className="w-1/2 overflow-hidden mt-2 pl-3">
            <Dicom />
          </div>
          <div className={`w-1/2 flex flex-col overflow-auto ${
            !isChatBotActive && isChatQuestionActive ? 'blur-sm' : ''
          }`}>
            <Editor />
            <Template />
          </div>
        </div>
      </div>
      <div className="h-[2vh]">
        <p className="py-1 text-right">
          © Copyright 2016 - 2024 by 5C Network. All Rights Reserved.
        </p>
      </div>
    </div>
    </>
  );
}

export default Reportbuilder;

