import React from 'react'
import loader from '../assets/loader.gif'

function Loader() {
  return (
    <div className='flex justify-center items-center' style={{height: '100vh'}}>
<div class="loader">
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__ball"></div>
</div>
</div>
  )
}

export default Loader