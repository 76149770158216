import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../utils/Navbar';
import reports from '../assets/reports.gif';
import { CheckCheck, Clock, ChevronDown, Database } from 'lucide-react';
import { firebase, firestore } from '../utils/Firebase';
import Login from '../utils/Login';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  ChartPieIcon,
  RefreshIcon,
  SearchIcon,
  UserIcon,
  CalendarIcon,
  DocumentTextIcon,
  EyeIcon
} from '@heroicons/react/outline';
import { setSelectedPatientData } from '../redux/ReportBuilder';
import { MdOutlineOpenInNew } from 'react-icons/md';

const Studieslist = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const formData = useSelector(state => state.reportBuilder.formData);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: '',
    role: ''
  });

  const [listOfUser, setListOfUser] = useState([])
  const [openDropdown, setOpenDropdown] = useState(null);





  useEffect(() => {
    const fetchUserData = async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        try {
          const userLevelDocRef = firestore.collection('evo-user-level').doc('FlCqYgtCEbNkLSgkjKOl');
          const docSnapshot = await userLevelDocRef.get();
          const listofuservalues = docSnapshot.data()['user-level'].map(user => user.Email.replace('@5cnetwork.com', ''));
          setListOfUser(listofuservalues)

          if (docSnapshot.exists) {
            const userLevels = docSnapshot.data()['user-level'];
            const userData = userLevels.find(u => u.Email === user.email);
            if (userData) {
              setUserData({
                email: userData.Email,
                role: userData.Role
              });
              console.log("User data:", userData);
            } else {
              console.log('User data not found for the email:', user.email);
            }
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, []);



  const fetchCases = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/pull-case?user=${user?.user?.email}`);
  
      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.message || `Status code: ${response.status}`;
        throw new Error(errorMessage); 
      }
  
      const data = await response.json();
      setPatients(data);
      toast.success('Patient data fetched successfully!');
    } catch (error) {
      console.error('Error fetching patient data:', error);
      toast.error(` ${error.message}`); 
    } finally {
      setLoading(false);
    }
  };
  


  useEffect(() => {
    const pullCases = async () => {
      if (userData.role) {
        setLoading(true);
        try {
          const userEmail = encodeURIComponent(userData.email);
          const userRole = encodeURIComponent(userData.role);
          const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/get-case?user=${userEmail}&role=${userRole}`);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setPatients(data);
        } catch (error) {
          console.error('Error fetching patient data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    pullCases();
  }, [userData]);





  const filteredPatients = patients.filter(patient =>
    patient.patient_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const completedCount = patients.filter(p => p.status.toLowerCase() === 'completed').length;
  const pendingCount = patients.filter(p => p.status.toLowerCase() === 'pending').length;


  const handleAssignUser = async (patientId, userEmail) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/assign-case`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          patient_id: patientId,
          user: userEmail.includes('@5cnetwork.com') ? userEmail : `${userEmail}@5cnetwork.com`
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to assign user');
      }

      setPatients(prevPatients =>
        prevPatients.map(patient =>
          patient.patient_id === patientId ? { ...patient, user: userEmail } : patient
        )
      );

      setOpenDropdown(null);
    } catch (error) {
      console.error('Error assigning user:', error);
    }
  };


  const handleViewClick = async (patient, user, dispatch, navigate) => {
    dispatch(setSelectedPatientData({
      patient_id: patient.patient_id,
      patient_name: patient.patient_name,
      patient_age: patient.patient_age,
      patient_sex: patient.patient_sex,
      mod_study: patient.mod_study,
      status: patient.status,
      study_iuid: patient.study_iuid,
      user: user.user.email,
    }));

    const status = patient.status.toLowerCase();

    if (status === 'completed') {
      navigate('/report');
    } else if (status === 'pending' || status === 'opened') {
      navigate('/report-builder');

      if (status === 'pending') {
        try {
          const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/update-status`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              patient_id: patient.patient_id,
              new_status: 'opened',
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to update patient status');
          }
        } catch (error) {
          console.error('Error updating patient status:', error);
        }
      }
    } else {
      console.error('Unknown status:', status);
    }
  };


  return (
    <>
    <ToastContainer/>
      <div>
        <Navbar user_name={formData?.patient_name || 'Guest'} mod_study={formData?.mod_study} patient_age={formData?.patient_age} patient_sex={formData.patient_sex} />

        <div className="bg-white p-8 pl-3 pr-3 h-screen fixed w-full">
          <div className="max-w-full mx-4">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="p-6 bg-gradient-to-r from-[#1a1c4e] to-[#2b3084]">
                <div className="flex justify-between items-center">
                  <h1 className="text-3xl font-bold text-white">Case List</h1>
                  {userData.role === 'admin' && (
                    <button
                      onClick={fetchCases}
                      disabled={loading}
                      className="bg-white text-[#2A2C6E] px-4 py-2 rounded-md font-semibold flex items-center transition duration-300 hover:bg-blue-50"
                    >
                      <RefreshIcon className="w-5 h-5 mr-2 text-[#2A2C6E]" />
                      {loading ? 'Loading...' : 'Get Case'}
                    </button>
                  )}
                </div>
              </div>

              <div className="p-6 lg:!h-[99%]">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    className="border p-4 rounded-lg shadow border-r-4 border-r-green-300 flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <ChartPieIcon className="w-8 h-8 text-green-500 mr-3" />
                      <div>
                        <p className="text-xs text-green-600 font-semibold">Completed Cases</p>
                        <p className="text-2xl font-bold text-green-700">{completedCount}</p>
                      </div>
                    </div>
                    <img src={reports} alt="Reports" className="w-12 h-12" />
                  </motion.div>

                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    className="border p-4 rounded-lg shadow border-r-4 border-r-yellow-300 flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <ChartPieIcon className="w-8 h-8 text-yellow-500 mr-3" />
                      <div>
                        <p className="text-xs text-yellow-600 font-semibold">Pending Cases</p>
                        <p className="text-2xl font-bold text-yellow-700">{pendingCount}</p>
                      </div>
                    </div>
                    <iframe
                      src="https://lottie.host/embed/32702c02-4826-4442-b71f-0b07f4f49288/Vvb93S9rUk.json"
                      className="w-12 h-12"
                      title="Pending Cases Animation"
                    ></iframe>
                  </motion.div>

                  <div className="relative">
                    <div className="flex items-center px-3 py-2 text-gray-400 border rounded-md focus-within:ring-2 ring-teal-500">
                      <SearchIcon className="w-5 h-5 stroke-slate-400 mr-2" />
                      <input
                        className="w-full bg-transparent text-sm text-gray-700 placeholder:text-gray-400 focus:outline-none"
                        placeholder="Find anything..."
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow overflow-hidden w-full h-[500px]">
                  <div className="overflow-x-auto h-full">
                    <table className="w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-100 sticky top-0 z-10">

                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[15%]">Patient ID</th>
                          <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[20%]">Patient Name</th>
                          <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[10%]">Age/Sex</th>
                          <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[15%]">Created At</th>
                          <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[15%]">Mod Study</th>
                          <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[15%]">Status</th>
                          {userData.role === 'admin' && (
                            <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[15%]">Assigned to</th>
                          )}
                          <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider w-[10%]">Action</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {loading ? (
                          <tr>
                            <td colSpan={7} className="px-6 py-4 text-center">
                              <div className="flex justify-center items-center">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                              </div>
                            </td>
                          </tr>
                        ) : filteredPatients.length === 0 ? (
                          <tr>
                            <td colSpan={7} className="px-6 py-4 text-center">
                              <div className="flex flex-row items-center gap-3 justify-center text-black opacity-40">
                                <Database size={22} />
                                <span className="text-lg">No cases available</span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          filteredPatients.map((patient) => (
                            <motion.tr
                              key={patient.patient_id}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <td className="px-6 py-4 whitespace-nowrap w-[15%]">
                                <div className="flex items-center">
                                  <UserIcon className="w-5 h-5 text-gray-400 mr-2" />
                                  <div className="text-sm font-medium text-gray-900">{patient.patient_id}</div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap w-[20%]">
                                <div className="text-sm text-gray-900">{patient.patient_name}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap w-[10%]">
                                <div className="text-sm text-gray-900">{`${patient.patient_age}/${patient.patient_sex.charAt(0)}`}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap w-[15%]">
                                <div className="flex items-center">
                                  <CalendarIcon className="w-5 h-5 text-gray-400 mr-2" />
                                  <div className="text-sm text-gray-500">{new Date(patient.created_at).toLocaleDateString('en-GB')}</div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap w-[15%]">
                                <div className="flex items-center">
                                  <DocumentTextIcon className="w-5 h-5 text-gray-400 mr-2" />
                                  <div className="text-sm text-gray-500">{patient.mod_study}</div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap w-[15%]">
                                <span className={`px-2 inline-flex items-center py-1 text-xs leading-5 font-semibold rounded-full ${patient.status.toLowerCase() === 'completed'
                                  ? 'bg-green-100 text-green-800'
                                  : patient.status.toLowerCase() === 'opened'
                                    ? 'bg-blue-100 text-[#3c82f6]'
                                    : 'bg-yellow-100 text-yellow-800'
                                  }`}>
                                  {patient.status.toLowerCase() === 'completed' ? (
                                    <CheckCheck className="mr-1" size={15} />
                                  ) : patient.status.toLowerCase() === 'opened' ? (
                                    <MdOutlineOpenInNew className="mr-1" size={15} />
                                  ) : (
                                    <Clock className="mr-1" size={15} />
                                  )}
                                  {patient.status}
                                </span>
                              </td>

                              {userData.role === 'admin' && (
  <AssignUserDropdown
    patient={patient}
    listOfUser={listOfUser}
    handleAssignUser={handleAssignUser}
    openDropdown={openDropdown}
    setOpenDropdown={setOpenDropdown}
  />
)}

                              <td className="px-6 py-4 whitespace-nowrap w-[10%]">
                                <button
                                  className={`text-blue-500 bg-[#dbeafe] hover:text-blue-600 p-[5px] text-sm rounded-md font-medium flex items-center`}
                                  onClick={() => handleViewClick(patient, user, dispatch, navigate)}
                                // disabled={patient.status.toLowerCase() === 'completed'}
                                >
                                  <EyeIcon className="w-5 h-5 mr-2" />
                                  View
                                </button>
                              </td>
                            </motion.tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};


const DropdownPortal = ({ isOpen, onClose, children }) => {
  const [portalRoot, setPortalRoot] = useState(null);

  useEffect(() => {
    const root = document.createElement('div');
    root.style.position = 'fixed';
    root.style.zIndex = '9999';
    root.style.top = '0';
    root.style.left = '0';
    root.style.width = '100%';
    root.style.height = '100%';
    root.style.pointerEvents = 'none';
    document.body.appendChild(root);
    setPortalRoot(root);

    return () => {
      document.body.removeChild(root);
    };
  }, []);

  if (!portalRoot || !isOpen) return null;

  return ReactDOM.createPortal(
    <div style={{ pointerEvents: 'auto' }}>
      {children}
    </div>,
    portalRoot
  );
};

const AssignUserDropdown = ({ patient, listOfUser, handleAssignUser, openDropdown, setOpenDropdown }) => {
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const handleOpenDropdown = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom, left: rect.left });
    setOpenDropdown(openDropdown === patient.patient_id ? null : patient.patient_id);
  };

  return (
    <td className="px-6 py-4 whitespace-nowrap w-[15%]">
      <div className="relative">
        <button
          onClick={handleOpenDropdown}
          className="w-full text-left flex items-center justify-between px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          {patient.user ? patient.user.replace('@5cnetwork.com', '') : "Assign User"}
          <ChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
        </button>
        <DropdownPortal isOpen={openDropdown === patient.patient_id}>
          <div 
            style={{
              position: 'absolute',
              top: `${dropdownPosition.top}px`,
              left: `${dropdownPosition.left}px`,
              width: '200px', // Adjust as needed
              backgroundColor: 'white',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              borderRadius: '4px',
              overflow: 'auto',
              maxHeight: '300px'
            }}
          >
            {listOfUser
              .filter(user => user !== patient.user?.replace('@5cnetwork.com', ''))
              .map((user) => (
                <div
                  key={user}
                  className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                  onClick={() => {
                    handleAssignUser(patient.patient_id, user);
                    setOpenDropdown(null);
                  }}
                >
                  {user}
                </div>
              ))}
          </div>
        </DropdownPortal>
      </div>
    </td>
  );
};

export default Studieslist;