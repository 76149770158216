import { createSlice } from '@reduxjs/toolkit';

// Helper functions to interact with session storage
const saveToSessionStorage = (pathologies) => {
  sessionStorage.setItem('selectedPathologies', JSON.stringify(pathologies));
};

const loadFromSessionStorage = () => {
  const storedPathologies = sessionStorage.getItem('selectedPathologies');
  return storedPathologies ? JSON.parse(storedPathologies) : [];
};

const selectedPathologiesSlice = createSlice({
  name: 'pathologies',
  initialState: {
    selectedPathologies: loadFromSessionStorage(), // Load from session storage initially
    isReportable: false,
    isGenerating: false
  },
  reducers: {
    addPathology: (state, action) => {
      state.selectedPathologies = state.selectedPathologies || [];
      if (!state.selectedPathologies.includes(action.payload)) {
        state.selectedPathologies.push(action.payload);
        saveToSessionStorage(state.selectedPathologies); // Save updated state to session storage
      }
    },
    
    removePathology: (state, action) => {
      state.selectedPathologies = state.selectedPathologies.filter(item => item !== action.payload);
      saveToSessionStorage(state.selectedPathologies); // Save updated state to session storage
    },
    
    resetSelectedPathology: (state, action) => {
      state.selectedPathologies = action.payload;
      saveToSessionStorage(state.selectedPathologies); // Save updated state to session storage
    },

    flipIsReportable: (state, action) => {
      state.isReportable = action.payload;
    },
    
    flipIsGenerating: (state, action) => {
      state.isGenerating = action.payload;
    },
  },
});

export const { addPathology, removePathology, resetSelectedPathology, flipIsReportable, flipIsGenerating } = selectedPathologiesSlice.actions;

export default selectedPathologiesSlice.reducer;
