import React, { useState, useEffect } from 'react';
import CustomDropdown from './OptionDropDown';
import { useDispatch,useSelector } from 'react-redux';
import { setWhisperData,whisperStopListening } from '../redux/Augnito';
// import Voice from './Voice'
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dicom() {
    const handle = useFullScreenHandle();
    const [link, setLink] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isResetLoading, setIsResetLoading] = useState(false);
    const [resetValue, setResetValue] = useState('');
    const formData = useSelector(state => state.reportBuilder.formData);
    const isEnabled=useSelector((state)=>state?.user?.isEnabled)


    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
    };

    useEffect(() => {
        const initialStudyIuid = formData.study_iuid;
        setResetValue(initialStudyIuid);
        const apiUrl = `https://api.5cnetwork.com/dicom/viewer-token?study_iuid=${initialStudyIuid}`;
    
        fetch(apiUrl, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log('link value', result);
                const newIframeSrc = `https://dcm.5cnetwork.com/?token=${result}`;
                setLink(newIframeSrc);
            })
            .catch((error) => console.error(error));
    }, [currentIndex]);

    const handleResetButtonClick = () => {
        setIsResetLoading(true);
        const apiUrl = `https://api.5cnetwork.com/dicom/restore?study_iuid=${resetValue}`;
        fetch(apiUrl, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log('link value', result);
                setIsResetLoading(false);
                toast.success("Restore completed!");
            })
            .catch((error) => {
                console.error(error);
                setIsResetLoading(false);
                toast.error("Restore failed.");
            });
    };

console.log("formdatafromdicom",formData)
   
      
    return (
        <div className="w-full h-[80vh] ml-2 relative top-[1%] flex flex-col overflow-hidden">
    <ToastContainer />
    <div className="bg-[#eff6ff] rounded-t-lg flex justify-end border-2 p-2" 
     style={{ boxShadow: '0px 2px 2px 0px #d3d3d3' }}>
    <div className="flex items-center gap-2">
        <div className="restore">
            <button 
                className="btn !h-[20px] min-h-[2.2rem] bg-[#ffffff] border-blue-500 hover:bg-[#ffffff]" 
                onClick={handleResetButtonClick}
                disabled={isResetLoading} 
            >
                {isResetLoading ? (
                    <span className="loading loading-spinner"></span>
                ) : (
                    <svg width="25px" height="25px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.88468 17C7.32466 19.1128 9.75033 20.5 12.5 20.5C16.9183 20.5 20.5 16.9183 20.5 12.5C20.5 8.08172 16.9183 4.5 12.5 4.5C8.08172 4.5 4.5 8.08172 4.5 12.5V13.5M12.5 8V12.5L15.5 15.5" stroke="#121923" strokeWidth="1.2"/>
                        <path d="M7 11L4.5 13.5L2 11" stroke="#121923" strokeWidth="1.2"/>
                    </svg>
                )}
            </button>
        </div>
        <button 
            className="btn !h-[20px] min-h-[2.2rem] bg-[#ffffff] border-blue-500 hover:bg-[#ffffff]" 
            onClick={handle.enter}
        >
            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9999 2C16.714 2 19.071 2 20.5354 3.46447C21.9999 4.92893 21.9999 7.28595 21.9999 12C21.9999 16.714 21.9999 19.0711 20.5354 20.5355C19.1784 21.8926 17.055 21.9921 12.9999 21.9994M2.00049 11C2.00779 6.94493 2.10734 4.8215 3.46438 3.46447C4.43813 2.49071 5.8065 2.16443 8 2.0551" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M12 12L17 7M17 7H13.25M17 7V10.75" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 18C2 16.1144 2 15.1716 2.58579 14.5858C3.17157 14 4.11438 14 6 14C7.88562 14 8.82843 14 9.41421 14.5858C10 15.1716 10 16.1144 10 18C10 19.8856 10 20.8284 9.41421 21.4142C8.82843 22 7.88562 22 6 22C4.11438 22 3.17157 22 2.58579 21.4142C2 20.8284 2 19.8856 2 18Z" stroke="#1C274C" strokeWidth="1.5"/>
            </svg>
        </button>
    </div>
</div>
    <FullScreen handle={handle} className="flex-grow overflow-hidden">
        <iframe
            src={link}
            id="viewer"
            title="Dicom-viewer"
            className="w-full h-full border-solid border-l-[2px] border-r-[2px] border-[#c4c4c4]"
            allowFullScreen="allowFullScreen"
            allow={'clipboard-read; clipboard-write'}
            frameBorder='0'
        ></iframe>
    </FullScreen>
    <div className="bg-[#eff6ff] p-4 rounded-b-lg shadow-md flex justify-center border-2" 
         style={{ boxShadow: '0px 2px 2px 0px #d3d3d3' }}>
    </div>
</div>

    );
}

export default Dicom;
